import React, { useState } from "react"
import { graphql } from "gatsby"
import Header from "../components/header"
import Footer from "../components/footer"
import SEO from "../components/SEO"
import Container from "react-bootstrap/Container"
import Img from "gatsby-image"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Lightbox } from "react-modal-image"

export default ({ data }) => {
  var [open, setOpen] = useState(false)
  var [imageSrc, setimageSrc] = useState("")
  var [imageAlt, setimageAlt] = useState("")

  function openPopup(e) {
    setimageSrc(e.currentTarget.getAttribute("data-src"))
    setimageAlt(e.currentTarget.getAttribute("data-alt"))
    setOpen(true)
  }

  function closePopup(e) {
    setOpen(false)
  }

  return (
    <div>
      <SEO pageTitle="Gallery" pageDescription="A privately owned luxury villa was designed and built by a British Sri Lankan Interior Designer as a second home." />
      <Header headerText="Home Page" />
      <Container fluid className="hp-container">
        <div className="gallery">
          {data.allMarkdownRemark.edges.map(({ node }) => (
            <Row key={node.id}>
              {node.frontmatter.gallery.map(({ image, title, alt },index) => (
                <Col lg={3} md={4} sm={6} xs={12} key={index}>
                  <div
                    onClick={openPopup}
                    onKeyPress={openPopup}
                    role="button"
                    tabIndex={index}
                    data-src={image.childImageSharp.fluid.src}
                    data-alt={alt}
                    className="image-container"
                  >
                    <Img
                      className="image-block"
                      fluid={image.childImageSharp.fluid}
                      alt={alt}
                      title={title}
                      objectFit="cover"                      
                    />
                  </div>
                </Col>
              ))}
            </Row>
          ))}
        </div>
        {open && (
          <Lightbox
            large={imageSrc}
            alt={imageAlt}
            onClose={closePopup}
            hideDownload
            hideZoom
          />
        )}
      </Container>
      <Footer />
    </div>
  )
}

export const query = graphql`
  query galleryQuery {
    allMarkdownRemark(
      filter: {
        fields: { contentType: { eq: "gallery" } }
        frontmatter: { title: { eq: "main" } }
      }
    ) {
      edges {
        node {
          frontmatter {
            title
            gallery {
              alt
              title
              image {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
